<template>
  <section class="about" style="background: #FFF;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner-s"
      >
        <v-layout
          class="hidden-sm-and-down"
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
      <v-container  grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="tnf-assistant-div">
            <h2>Door&Window housekeeper</h2>
            <h4>门窗管家</h4>
            <h5> </h5>
            <v-layout wrap>
              <template v-for="team in info.team">
                <v-flex xs12 sm12 md12 lg12 mb-4 pa-2 pt-12 :key="team.id">
                  <h6 class="category-name" :style="'background:url('+team.img+') left center no-repeat;'">{{team.name}}</h6>
                </v-flex>
                <v-flex xs6 sm3 md2 lg1 mb-4 pa-2 v-for="user in team.users" :key="user.id">
                  <v-flex xs12 sm12 md12 lg12>
                    <v-dialog
                      :v-model="'dialog'+user.id"
                      scrollable
                      overlay-color="#000"
                      overlay-opacity=0.1
                      transition="dialog-top-transition"
                      max-width="750"
                      class="user-dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="user-div" v-bind="attrs" v-on="on" >
                          <div class="user-avatar-div"><img :src="user.avatar" class="user-avatar"/></div>
                          <div class="user-name">{{user.name}}</div>
                        </div>
                      </template>
                      <v-card>
                        <v-card-text>
                          <img :src="user.photo" class="user-photo">
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-flex>
              </template>
              <v-flex xs12 sm12 md12 lg12>
                <h1 class="tnf-large-text">DOOR&WINDOW HOUSEKEEPER</h1>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth,
    tabSubMenu: null,
    subMenu: [
      { key: 'about', name: '公司简介' },
      { key: 'story', name: '品牌故事' },
      { key: 'history', name: '发展历程' },
      { key: 'honor', name: '企业荣誉' },
      { key: 'assistant', name: '门窗管家' },
      { key: 'partner', name: '合作伙伴' }
    ]
  }),
  created () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
        this.info.team = val.team
      },
      deep: true
    }
  },
  computed: {
    info () {
      let info = this.$store.getters.companyInfo
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      info.banners = banners
      return info
    }
  }
}
</script>
<style lang="css">
  @import '../css/assistant.css';
</style>
